/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 * DataLayer push on file download click event.
 * This function is used also for promo box click event (promoBoxListeners.js).
 * @param {Event} event - The click event object.
 */
export const clickOnFileDownload = (event) => {
  let linkText;
  let componentHeading;
  let targetHref;
  const $currentTarget = $(event.currentTarget);

  try {
    targetHref = new URL($currentTarget.attr('href'));
  } catch (error) {
    return;
  }

  const fileExtension = targetHref.pathname.split('.').pop();

  if ($currentTarget.hasClass('ga-pushbox-link')) {
    componentHeading = $currentTarget.find('h2').text().trim();
    linkText = $currentTarget.find('.btn span').text().trim();
  } else if ($currentTarget.hasClass('ga-banner-cta-promotion')) {
    componentHeading = $currentTarget.find('.cta-title').text().trim();
    linkText = $currentTarget.find('.cta-btn-text').text().trim();
  } else if (($currentTarget.closest('.ProductContent').length > 0)) {
    componentHeading = $currentTarget.closest('.ProductContent').find('.product-title').text().trim();
    linkText = $currentTarget.text().trim();
  } else if ($currentTarget.hasClass('footer-text-angle-link')) {
    componentHeading = 'Download the Symbol Glossary';
    linkText = $currentTarget.text().trim();
  } else if ($currentTarget.hasClass('ga-campaign-promotion')) {
    componentHeading = $currentTarget.closest('.campaign-column').find('.campaign-title').text().trim() || 'Download the Sports Medicine Catalog';
    linkText = $currentTarget.text().trim();
  } else {
    componentHeading = $currentTarget.closest('.item-box-content').find('h2.title').text().trim();
    linkText = $currentTarget.find('span').text().trim();
  }

  const allowedFileTypes = ['pdf', 'doc', 'docx', 'xml', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx', 'zip', 'rar'];

  if (allowedFileTypes.includes(fileExtension)) {
    pushToDataLayer({
      event: 'ga4_file_download',
      ga4_data: {
        file_extension: fileExtension.toUpperCase(),
        file_name: componentHeading,
        link_text: linkText,
        link_url: targetHref.href,
        file_type: 'General download',
        link_domain: targetHref.hostname,
      }
    });
  }
}

export const addFileDownloadListeners = () => {
  // File download event listener for:
  // item box (item-box.hbs),
  // call to action (call-to-action-item.hbs),
  // product-intro (product-intro-content.hbs),
  // footer (footer-column-item.hbs),
  // campaign (campaign-image-content.hbs).
  $('.ga4-item-box-link, .ga-banner-cta-promotion, ProductContent a, .footer-text-angle-link, .ga-campaign-promotion').on('click', event => clickOnFileDownload(event));
};
