/**
 * Returns pathname from full url.
 * @param {Sting} href
 * @returns {String}
 */
export const getPathname = href => {
  const link = document.createElement('a');
  link.href = href;

  return link.pathname;
};
