/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';
import { convertUrlToAbsolute } from './../../helpers/convertUrlToAbsolute';
import { getPathname } from './../../helpers/getPathname';

/**
 *  DataLayer push on header CTA event.
 */
const clickOnHeaderCTA = () => {
  $('.ga-header-item-link').on('click', event => {
    const $clickedText = $(event.currentTarget).find('.title').text().trim();
    const isSameDomen = event.currentTarget.hostname === window.location.hostname;
    const targetURL = convertUrlToAbsolute(event.currentTarget.href);

    const eventLabel = isSameDomen
      ? getPathname(event.currentTarget)
      : convertUrlToAbsolute(event.currentTarget.href);

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'headerNavigation',
      eventCategory: 'Header CTAs',
      eventAction: $clickedText,
      eventLabel
    });

    pushToDataLayer({
      event: 'ga4_select_header_cta',
      ga4_data: {
        link_text: $clickedText,
        link_url: targetURL
      }
    });
  });
}

const clickOnWhereToBuyHeaderCTA = () => {
  $('.ga4-where-to-buy').on('click', () => {
    pushToDataLayer({
      event: 'where_to_buy_header_cta'
    });
  })
}

export const addHeaderCTAListeners = () => {
  clickOnHeaderCTA();
  clickOnWhereToBuyHeaderCTA();
};