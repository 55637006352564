import { pushToDataLayer } from '../pushToDataLayer';
import { clickOnFileDownload } from './fileDownloadListener';

/**
 *  DataLayer push on Promo Box click event.
 */
function clickOnPromoBox() {
  $('.ga-pushbox-link').on('click', event => {
    clickOnFileDownload(event); // File download event listener

    const targetHref = new URL(event.currentTarget.href);
    const eventAction = targetHref.pathname;

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'contentInteraction',
      eventCategory: 'Promo Box',
      eventAction,
      eventLabel: window.location.href,
      eventValue: 'undefined',
    });
  });
}

export const addPromoBoxListeners = () => {
  clickOnPromoBox();
};
