import { pushToDataLayer } from '../pushToDataLayer';
import { convertUrlToAbsolute } from './../../helpers/convertUrlToAbsolute';
import { getPathname } from './../../helpers/getPathname';

/**
 *  DataLayer push on Hero Banner(intro-carousel, campaign-media-container, camapign-image-container) click event.
 */
function clickOnHeroBanner() {
  $('.js-region-intro .ga-hero-banner-link').on('click', event => {
    const eventLabel = convertUrlToAbsolute(window.location.href);
    const isSameDomen = event.currentTarget.hostname === window.location.hostname;

    const eventAction = isSameDomen
      ? getPathname(event.currentTarget)
      : convertUrlToAbsolute(event.currentTarget.href);

    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'bannerClick',
      eventCategory: 'Hero Banner Click',
      eventAction,
      eventLabel
    });
  });
}

export const addHeroBannerListeners = () => {
  clickOnHeroBanner();
};
