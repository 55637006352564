/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 *  DataLayer push on Campaign-image-container ctas and video click event.
 *  DataLayer push on CTA item.
 */
function clickOnCampaignImageContainer() {
  $('.ga-campaign-promotion').on('click', event => {
    pushToDataLayer({
      event: 'ga4_select_promotion',
      ga4_data: {
        creative_name: 'Campaign box',
        promotion_name: $(event.currentTarget).data('promotion-name')
      }
    });
  });

  $('.ga-banner-cta-promotion').on('click', event => {
    pushToDataLayer({
      event: 'ga4_select_promotion',
      ga4_data: {
        creative_name: 'Banner CTA',
        promotion_name: $(event.currentTarget).data('promotion-name')
      }
    });
  });
}

/**
 *  Datalayer push on elements with the 'ga4-select-promotion' class.
 *  @param {jQuery.Event} event - The jQuery click event.
 */
function handlePromotionClickAnalytics() {
  $('.ga4-select-promotion').on('click', event => {
    const $target = $(event.currentTarget);

    pushToDataLayer({
      event: 'ga4_select_promotion',
      ga4_data: {
        creative_name: $target.data('creative-name'),
        promotion_name: $target.data('promotion-name')
      }
    });
  })
}

export const addCampaignListeners = () => {
  clickOnCampaignImageContainer();
  handlePromotionClickAnalytics();
};
