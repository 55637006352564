/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 * Attaches event listeners to forms for analytics tracking.
 * Depending on the form type, different events are pushed to the dataLayer.
 */
const formListeners = () => {
  const $form = $('.js-form');

  if (!$form.length) {
    return;
  }

  const formType = $form.data('formtype').toLowerCase().replace(/\s/g, '');
  const trackedFormId = $('form').attr('id');
  sessionStorage.setItem('trackedFormId', trackedFormId);
  let isFormVisible = false;
  let isFormStarted = false;

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !isFormVisible) {
        switch (formType) {
          case 'contactus':
            pushToDataLayer({
              event: 'ga4_form_visible',
              ga4_data: {
                form_name: 'Contact us',
                form_id: trackedFormId
              }
            });
            break;
          case 'newsletter':
            pushToDataLayer({
              event: 'ga4_form_visible',
              ga4_data: {
                form_name: 'Newsletter form',
                form_id: trackedFormId
              }
            });
            break;
          case 'askthedoctor':
            pushToDataLayer({
              event: 'ga4_form_visible',
              ga4_data: {
                form_name: 'Ask the doctor',
                form_id: trackedFormId
              }
            });
            break;
        }
        isFormVisible  = true;
      }
    });
  }, {
    root: null,
    threshold: 0.1
  });

  observer.observe($form.get(0));

  const triggerFormFillEvent = (formName) => {
    pushToDataLayer({
      event: 'ga4_form_start',
      ga4_data: {
        form_name: formName,
        form_id: trackedFormId
      }
    });
  };

  $form.find('.form-field').on('change', () => {
    if (!isFormStarted) {
      switch (formType) {
        case 'contactus':
          triggerFormFillEvent('Contact us');
          break;
        case 'newsletter':
          triggerFormFillEvent('Newsletter form');
          break;
        case 'askthedoctor':
          triggerFormFillEvent('Ask the doctor');
          break;
      }
      isFormStarted = true;
    }
  });
}

export const addFormListeners = () => {
  formListeners();
};
