import { pushToDataLayer } from '../pushToDataLayer';

function clickOnArticleLink() {
	$('.ga-article-link').on('click', event => {
		pushToDataLayer({
			event: 'engagement',
			action: 'article-read',
			url: event.currentTarget.href
		});
	});
}

export const addArticleListeners = () => {
	clickOnArticleLink();
};
