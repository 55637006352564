import { pushToDataLayer } from '../pushToDataLayer';

export const addPageLoadEvents = () => {
	const $pageLoad = $('.ga-page-load-events');

	if ($pageLoad.length) {
		switch ($pageLoad.data('pagetype')) {
			case 'article':
				pushToDataLayer({
					event: 'engagement',
					action: 'article-read',
					url: window.top.location
				});
				break;
			case 'productdetail':
				pushToDataLayer({
					event: 'engagement',
					action: 'product-view',
					url: window.top.location
				});
				break;
			default:
				break;
		}
	}
};
